// Footer.js
import React from 'react';
import '../styles/Footer.css'; // Make sure you style the footer properly

const Footer = () => {
  return (
    <footer className="app-footer">
      <p>&copy; {new Date().getFullYear()} Kudiltech. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
