import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ChatMessage from './ChatMessage';
import ChatOptions from './ChatOptions';
import '../styles/Chatbot.css';

const Chatbot = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isListening, setIsListening] = useState(false);
  const [expandedCards, setExpandedCards] = useState({});
  const [selectedCourse, setSelectedCourse] = useState(null); 
  const messagesEndRef = useRef(null);


  const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
  const recognition = SpeechRecognition ? new SpeechRecognition() : null;

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleSendMessage = async (messageText) => {
    if (!messageText.trim()) return;

    const userMessage = { sender: 'user', text: messageText };
    setMessages((prev) => [...prev, userMessage]);
    setInput('');
    const headers = {};
    headers['Content-Type'] = 'application/json';
    axios.defaults.withCredentials = true;
    try {
      const response = await axios.post('https://api.loan.ask-myna.com/ask-myna', {
        query: messageText,
        languageCode: 'en-US'
      });
      const agentResponse = response.data.response || 'Sorry, I did not understand that.';

      // Handle "Credit score" case
      const quotePattern = /PROD\d+\.\s*\*Credit score\*\s*:\s*(.*?)\s*(?=PROD|$)/g;
      const quotes = [];
      let match1;

      while ((match1 = quotePattern.exec(agentResponse)) !== null) {
        quotes.push(match1[1].trim());
      }

      if (quotes.length > 0) {
        const agentMessageWithQuotes = {
          sender: 'agent',
          text: agentResponse.split('PROD1')[0],
          options: quotes, // Set quotes as options
        };
        setMessages((prev) => [...prev, agentMessageWithQuotes]);
        return; // Exit early
      }
      else {

        // Extracting product list dynamically
        console.log('Raw server response:', agentResponse);
        // Regex pattern for extracting products dynamically
        const productPattern = /PROD\d+\.\s*(.*?)\s*(?=PROD|$)/g;
        const products = [];

        let match;

        // Extract products from the agent response
        while ((match = productPattern.exec(agentResponse)) !== null) {
          products.push(match[1].trim());
        }

        // Check if the text "ROWS FOR PROD1" is in the agent response
        if (!agentResponse.includes("ROWS FOR PROD1")) {
          const agentMessage = {
            sender: 'agent',
            text: agentResponse.split('PROD1')[0], // Dynamic message extraction
            options: products,  // Dynamic options
          };
          setMessages((prev) => [...prev, agentMessage]);
        } else {

          // Extracting product details

          const detailPattern = /PROD(\d+)\.\s*\*Program\*\s*:\s*(.*?)\s*\*Rate\*\s*:\s*([0-9.,%$-]+)\s*\*APR\*\s*:\s*([0-9.,%$-]+)\s*\*Monthly Payment\*\s*:\s*([0-9.,%$-]+)\s*\*mynaId\*\s*:\s*(.*?)\s*\*programId\*\s*:\s*(.*?)\s*\*rateOptionId\*\s*:\s*(.*?)($|\s*ROWS FOR PROD)/gis;
          const productDetails = [];
          const additionalRows = {};

          while ((match = detailPattern.exec(agentResponse)) !== null) {
            const productId = parseInt(match[1], 10);
            const courseName = match[2].trim();
            const productDetail = {
              productId,
              courseName,
              passPercent: match[3].trim(),
              obtainedPercent: match[4].trim(),
              totalMarks: match[5].trim(),
              id1: match[6].trim(),
              id2: match[7].trim(),
              id3: match[8].trim(),
            };
            productDetails.push(productDetail);
            additionalRows[courseName] = [];
          }
          const productRowPattern = /ROWS FOR PROD(\d+)\.\s*([\s\S]*?)(?=PROD\d+\.|\s*$)/g;
          let rowMatch;

          while ((rowMatch = productRowPattern.exec(agentResponse)) !== null) {
            const productId = parseInt(rowMatch[1], 10);
            const rowsText = rowMatch[2].trim();
            // Log extracted rowsText for debugging
            console.log(`Extracted rows for PROD${productId}:`, rowsText);
            const rowDetailPattern = /(?:\*Rate\*: ([\d.,%$-]+))|(?:\*APR\*: ([\d.,%$-]+))|(?:\*Monthly Payment\*: ([\d.,%$-]+))|(?:\*mynaId\*: ([\w-]+))|(?:\*programId\*: ([\w-]+))|(?:\*rateOptionId\*: ([\w-]+))|(?:\*Closing Cost\*: ([\w\$\.,]+))|(?:\*Price\*: ([\d.]+))/g;

            let rowDetailMatch;
            const rowsForProduct = [];

            while ((rowDetailMatch = rowDetailPattern.exec(rowsText)) !== null) {
              console.log('Row detail match:', rowDetailMatch); // Log each match
              const row = {
                passPercent: rowDetailMatch[1]?.trim(),
                obtainedPercent: rowDetailMatch[2]?.trim(),
                totalMarks: rowDetailMatch[3]?.trim(),
                id1: rowDetailMatch[4]?.trim(),
                id2: rowDetailMatch[5]?.trim(),
                id3: rowDetailMatch[6]?.trim(),
                grade: rowDetailMatch[8]?.trim() || null,
                position: rowDetailMatch[9]?.trim() || null,
              };

              if (row.passPercent && row.obtainedPercent && row.totalMarks) {
                rowsForProduct.push(row);
              }
            }

            const product = productDetails.find((prod) => prod.productId === productId);
            if (product) {
              additionalRows[product.courseName] = rowsForProduct;
              console.log(`Rows for ${product.courseName}:`, rowsForProduct); // Final check
            }
          }

          // Final additional rows output for verification
          console.log("Final additionalRows:", additionalRows);

          // Check for rows if none captured initially - PROD1
          if (!additionalRows[productDetails[0].courseName].length) {
            console.log('Raw server response:', agentResponse);
            const productRowPattern1 = /ROWS FOR PROD1\.(.*?)(?=PROD\d+\.|\s*$)/gs;
            const prod1Match = productRowPattern1.exec(agentResponse);

            if (prod1Match) {
              let prod1Block = prod1Match[1].trim();
              console.log('Extracted block for PROD1:', JSON.stringify(prod1Block));

              const sanitizedProd1Block = prod1Block.replace(/\s+/g, ' ').trim();
              console.log('Sanitized block for PROD1:', JSON.stringify(sanitizedProd1Block));

              const rowPattern1 = /\*Rate\*\s*:\s*([0-9.,%$-]+)\s*\*APR\*\s*:\s*([0-9.,%$-]+)\s*\*Monthly Payment\*\s*:\s*([0-9.,%$-]+)\s*\*mynaId\*\s*:\s*(.*?)\s*\*programId\*\s*:\s*(.*?)\s*\*rateOptionId\*\s*:\s*(.*?)\s*\*Closing Cost\*\s*:\s*(.*?)\s*\*Price\*\s*:\s*(.*?)\s*(?=\*Rate\*|\s*$)/g;

              const rowsProd1 = [];
              let rowMatch1;

              console.log('Sanitized block for PROD1:', JSON.stringify(sanitizedProd1Block));

              while ((rowMatch1 = rowPattern1.exec(sanitizedProd1Block)) !== null) {
                const rowData = {
                  passPercent: rowMatch1[1],
                  obtainedPercent: rowMatch1[2],
                  totalMarks: rowMatch1[3],
                  id1: rowMatch1[4],
                  id2: rowMatch1[5],
                  id3: rowMatch1[6],
                  grade: rowMatch1[7],
                  position: rowMatch1[8],
                };

                rowsProd1.push(rowData);
                console.log('Captured row data:', rowData);
              }

              if (rowsProd1.length === 0) {
                console.log('No rows captured for PROD1.');
              } else {
                additionalRows[productDetails[0].courseName] = rowsProd1;
              }
            }
            console.log('Final additionalRows:', additionalRows); // Log final additionalRows
          }
          // Check for rows if none captured initially
          console.log('Checking additionalRows for captured data.');
          for (let product of productDetails) {
            const productId = product.productId;
            const courseName = product.courseName;

            // Check if there are rows for the current product
            if (!additionalRows[courseName]?.length) {
              console.log(`Raw server response for ${courseName}:`, agentResponse);

              // Create a regex pattern for dynamic product rows extraction
              const productRowPattern = new RegExp(`ROWS FOR PROD${productId}\\.\\s*(.*?)\\s*(?=PROD\\d+\\.|$)`, 'gs');
              const productMatch = productRowPattern.exec(agentResponse);

              if (productMatch) {
                let productBlock = productMatch[1].trim();
                console.log(`Extracted block for ${courseName}:`, JSON.stringify(productBlock));

                const sanitizedProductBlock = productBlock.replace(/\s+/g, ' ').trim();
                console.log(`Sanitized block for ${courseName}:`, JSON.stringify(sanitizedProductBlock));

                // Update the row pattern to match the required fields
                const rowPattern = /\*Rate\*\s*:\s*([0-9.,%$-]+)\s*\*APR\*\s*:\s*([0-9.,%$-]+)\s*\*Monthly Payment\*\s*:\s*([0-9.,%$-]+)\s*\*mynaId\*\s*:\s*(.*?)\s*\*programId\*\s*:\s*(.*?)\s*\*rateOptionId\*\s*:\s*(.*?)\s*\*Closing Cost\*\s*:\s*(.*?)\s*\*Price\*\s*:\s*(.*?)\s*(?=\*Rate\*|$)/g;

                const rowsProduct = [];
                let rowMatch;

                console.log(`Sanitized block for ${courseName}:`, JSON.stringify(sanitizedProductBlock));

                while ((rowMatch = rowPattern.exec(sanitizedProductBlock)) !== null) {
                  const rowData = {
                    passPercent: rowMatch[1],
                    obtainedPercent: rowMatch[2],
                    totalMarks: rowMatch[3],
                    id1: rowMatch[4],
                    id2: rowMatch[5],
                    id3: rowMatch[6],
                    grade: rowMatch[7],
                    position: rowMatch[8],
                  };

                  rowsProduct.push(rowData);
                  console.log(`Captured row data for ${courseName}:`, rowData);
                }

                if (rowsProduct.length === 0) {
                  console.log(`No rows captured for ${courseName}.`);
                } else {
                  additionalRows[courseName] = rowsProduct;
                }
              }
              console.log(`Final additionalRows for ${courseName}:`, additionalRows); // Log final additionalRows for current product
            }
          }

          // Add agent's message with product cards and rows
          const agentMessageWithCards = {
            sender: 'agent',
            text: agentResponse.split('PROD1')[0],  // Text before PROD1
            productsCard: productDetails,
            additionalRows: additionalRows,
          };
          setMessages((prev) => [...prev, agentMessageWithCards]);


          // Log the final message state with product cards and rows
          console.log('Agent message with product cards and rows:', agentMessageWithCards);
        }
      }
    } catch (error) {
      console.error("Error fetching response:", error);
      setMessages((prev) => [...prev, { sender: 'agent', text: 'Error fetching response from server.' }]);
    }

    setInput('');
  };
  const handleOptionSelect = (optionValue) => {
    handleSendMessage(optionValue);
  };
  const sendHiddenMessage = (message) => {
    // Simulate sending the hidden message to the backend, but without showing it in the chat
    console.log("Hidden message sent:", message); // This logs the hidden message in the console
    handleSendMessage(message, { silent: true }); // Send the message silently without displaying it to the chat
  };

  const handleCardOptionSelect = (product) => {
    const { courseName, passPercent, obtainedPercent, totalMarks, id1, id2, id3 } = product;

    // Send the hidden message with the IDs to the agent, without showing it in the chat
    sendHiddenMessage(`mynaId: ${id1}, programId: ${id2}, rateOptionId: ${id3}`); // Corrected message format

    // Create the visible message to show to the user as an agent message
    const visibleMessage = `Program: ${courseName}, Rate: ${passPercent}, APR: ${obtainedPercent}, Monthly Payment: ${totalMarks}`;

    // Show the visible message in the chat as an agent message
    //handleSendMessage(visibleMessage, { agent: true }); // Show as agent message
  };

  const toggleCardExpansion = (index) => {
    setExpandedCards((prevExpanded) => ({
      ...prevExpanded,
      [index]: !prevExpanded[index]
    }));
  };

  const toggleSpeechRecognition = () => {
    if (!recognition) return;

    if (isListening) {
      recognition.stop();
      setIsListening(false);
    } else {
      recognition.start();
      setIsListening(true);
    }

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setInput(transcript);
      handleSendMessage(transcript);
    };

    recognition.onend = () => {
      setIsListening(false);
    };
  };

  return (
    <div className="chatbot-container">
      <div className="header" style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '1px 20px',
        backgroundColor: '#7ca1e2', // Light blue/grey background
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle shadow for separation
        borderBottom: '2px solid #d1e3f3', // Consistent border size
        height: '55px' // Ensure height is large enough for bigger logo
      }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src="logo.png" alt="Logo" style={{ height: '80px' }} />
          {/* Increased logo size */}
          <h1 style={{ fontSize: '1.0rem', fontWeight: '600', color: '#333', margin: 0, alignItems: 'center' }}>
            Myna - AI Loan Officer
          </h1>
        </div>


      </div>

      <div className="chat-window">
        {messages.map((msg, index) => (
          <ChatMessage
            key={index}
            message={msg}
            userClass="user-message"
            agentClass="agent-message"
          />
        ))}
        <div ref={messagesEndRef} />
        {messages.length > 0 && messages[messages.length - 1].options && (
          <div className="options-container">
            <ChatOptions options={messages[messages.length - 1].options} onOptionSelect={handleOptionSelect} />
          </div>
        )}
        {/* Render product cards when more than 3 products are available */}
        {messages.length > 0 && messages[messages.length - 1].productsCard && (
          <div className="card-container">
            {messages[messages.length - 1].productsCard
              .filter((product) => !selectedCourse || product.courseName === selectedCourse) // Hide unselected courses
              .map((product, index) => (
                <div className="product-card" key={index}>
                  <h3>{product.courseName}</h3>
                  <p><strong>Rate:</strong> {product.passPercent}</p>
                  <p><strong>APR:</strong> {product.obtainedPercent}</p>
                  <p><strong>Monthly Payment:</strong> {product.totalMarks}</p>

                  <div className="card-options">
                    <button
                      onClick={() => handleCardOptionSelect(product)}
                      disabled={selectedCourse !== null}>Apply</button> {/* Disable button if a course is selected */}
                    <button
                      onClick={() => toggleCardExpansion(index)}
                      disabled={selectedCourse !== null}>
                      {expandedCards[index] ? 'Show rates' : 'Show rates'}
                    </button>
                  </div>

                  {expandedCards[index] && (
                    <div className="expanded-table">
                      <table>
                        <thead>
                          <tr>
                            <th>Rate</th>
                            <th>APR</th>
                            <th>Monthly Payment</th>
                            <th>Closing Cost</th>
                            <th>Price</th>
                            <th>Select loan</th>
                          </tr>
                        </thead>
                        <tbody>
                          {messages[messages.length - 1].additionalRows[product.courseName]?.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                              <td>{row.passPercent}</td>
                              <td>{row.obtainedPercent}</td>
                              <td>{row.totalMarks}</td>
                              <td>{row.grade}</td>
                              <td>{row.position}</td>
                              <td>
                                <button
                                  className="select-button"
                                  onClick={() => handleCardOptionSelect(product)}
                                  disabled={selectedCourse !== null}>Apply</button> {/* Disable if course selected */}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              ))}
          </div>
        )}
      </div>
      <div className="chat-input-container">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type a message or use speech..."
          className="chat-input"
          onKeyPress={(e) => { if (e.key === 'Enter') handleSendMessage(input); }}
        />
        <button onClick={() => handleSendMessage(input)} className="send-button">Send</button>
        <button onClick={toggleSpeechRecognition} className={`speech-button ${isListening ? 'listening' : ''}`}>
          🎤 {isListening ? 'Listening...' : ''}
        </button>
      </div>
      <div className="footer" style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 10px', // Less padding for thinner footer
        backgroundColor: '#7ca1e2', // Match the header color
        borderTop: '1px solid #d1e3f3',
        height: '30px', // Make the footer thin
        position: 'relative', // Use 'relative' here so it doesn't overlap with page footer
        width: '98%',
        boxShadow: '0 -2px 4px rgba(0, 0, 0, 0.1)',
      }}>
        <span style={{ fontSize: '0.9rem', color: '#333' }}>          This AI chat is powered by Kudiltech        </span>
      </div>

    </div>
  );
};

export default Chatbot;

