import React from 'react';
import '../styles/ChatOptions.css';

const ChatOptions = ({ options, onOptionSelect }) => {
  return (
    <div className="options">
      {options.map((option, index) => (
        <button key={index} className="option-button" onClick={() => onOptionSelect(option)}>
          {option}
        </button>
      ))}
    </div>
  );
};

export default ChatOptions;
